// Variable overrides

//cor primary: blue
$bluep: #233a80;
$blue1: #89a3f0;
$blue2: #406bed;
$blue3: #3254ba;
$blue4: #3f4a6e;

//cor primary: pink
$pink: #e5004e;
$pink1: #f04880;
$pink2: #ed004f;
$pink3: #ba003e;
$pink4: #6e213a;

//cor secondary: cyan
$cyan: #41bbc9;
$cyan1: #95e7f0;
$cyan2: #4cdded;
$cyan3: #3cadba;
$cyan4: #44696e;

//cor buttons yellow
$yellow: #f7be00;
$yellow1: #f0c948;
$yellow2: #edb600;
$yellow3: #ba8f00;
$yellow4: #6e5c21;

//grayscale
$black: #000000;
$grey: #cecece;
$grey_light: #efefef;
$white: #ffffff;


::ng-deep{
    .spinner-three-bounce{
      position: fixed !important;
    }
    .backdrop {
      position: fixed !important;
    }
}