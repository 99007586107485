// Here you can add other styles
.app-header .navbar-brand {
    width: 200px!important;
    cursor: auto;
}

.pointer {
    cursor: pointer;
}

.ng-invalid.ng-touched {
    border-color: #f05050;
}

div.ng-invalid.ng-touched {}